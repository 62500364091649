<template>
    <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
      <el-sub-menu v-for="(category, index) in categories" :key="index" :index="`/products?category=${category.slug}`">
        <template #title>
          <p @click="clickCategory(category.slug)">{{ category.name }}</p>
        </template>
        <el-sub-menu v-if="category.grandchildren.length" :index="`${index}-${childIndex}`"
                     v-for="(child,childIndex) in category.grandchildren">
          <template #title>
            <p @click="clickCategory(child.slug)">{{ child.name }}</p></template>
          <el-menu-item
              v-if="child.grandchildren.length"
              v-for="(grand,grandIndex) in child.grandchildren" :index="`${index}-${childIndex}-${grandIndex}`">
            <p @click="clickCategory(grand.slug)">{{ grand.name }}</p>
          </el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
      </el-menu>
</template>
<script>
import axios from 'axios';
export default {
    name: 'MobileHeader',
    data() {
    return {
      activeIndex: '',
      categories: [],
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      axios.get(`/api/web/categories`)
          .then(res => {
            this.categories = res.data.data;
          });
    },
    handleSelect(key, keyPath) {
      console.log('kay', key, 'keypath', keyPath);
    },
    clickCategory(slug) {
      this.$router.push(`/products?category=${slug}`);
    },
  }
}
</script>