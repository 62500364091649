<template>
  <footer class="w3-footer">
    <div class="container">
      <div>
      <div class="footer-logo">
        BD ROYEL CULTURE
      </div>
      <div class="menu-with-social-media">
        <ul class="footer-menu">
          <li><a href="#" target="_blank">Blog</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Contact Us</a></li>
        </ul>
        <ul class="footer-social-media">
          <li><a href="https://www.facebook.com/bdroyalculture2" target="_blank">   
            <i class="fab fa-square-facebook p-0 text-15px"></i>

          </a></li>
          <li><a href="#" target="_blank"><i class="fab fa-youtube p-0 text-15px"></i></a></li>
          <li><a href=""><i class="fab fa-linkedin p-0 text-15px"></i></a></li>
          <li><a href=""><i class="fab fa-twitter p-0 text-15px"></i></a></li>
        </ul>
      </div>
      <div class="footer-menu-bottom mt-20">
        <a target="_blank" href="#">
         Copy Right By BD Royel Culture 
         
      
       

        </a>
        <div>
          <ul class="bottom-footer-menu">
            <li><a href="#">Privacy policy</a></li>
            <li><a href="#">Terms and condition</a></li>
            <li><a href="#">Faq</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  
};
</script>

<style scoped>
.w3-footer {
  background-color: #fff;
  border: 1px solid rgba(71, 85, 105, .1);
  padding: 50px 50px;
  margin-top: 50px;
}

.menu-with-social-media {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-menu {
  display: flex;
  /*padding-right: 10px;*/
  font-size: .875rem;
  line-height: 1.25rem;
  color: #0B0718;
}

.menu-with-social-media ul li a {
  padding-right: 20px;
  color: #0B0718;
}

.footer-social-media {
  display: flex;
}

.footer-social-media ul {
  margin: 0;
  padding: 0;
}

.footer-social-media ul li a {
  padding-right: 20px;
  color: #0B0718;
}

.footer-logo {
  /* width: 177px;
  height: 44px; */
  margin-bottom: 30px;
}

.footer-menu-bottom {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom-footer-menu {
  display: flex;
  color: #0B0718;
}

.bottom-footer-menu li a {
  padding-right: 20px;
  color: #0B0718;
  font-size: .875rem;
  line-height: 1.25rem;
}
</style>
