<template>
  <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      @select="handleSelect"
  >
    <div class="d-flex align-items-center justify-content-center  mx-100">
      <el-sub-menu v-for="(category, index) in categories" :key="index" :index="`/products?category=${category.slug}`">
        <template #title>
          <p @click="clickCategory(category.slug)" style="margin: 0px 20px">{{ category.name }}</p>
        </template>
        <el-sub-menu v-if="category.grandchildren.length" :index="`${index}-${childIndex}`"
                     v-for="(child,childIndex) in category.grandchildren">
          <template #title>
            <p @click="clickCategory(child.slug)">{{ child.name }}</p></template>
          <el-menu-item
              v-if="child.grandchildren.length"
              v-for="(grand,grandIndex) in child.grandchildren" :index="`${index}-${childIndex}-${grandIndex}`">
            <p @click="clickCategory(grand.slug)">{{ grand.name }}</p>
          </el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
    </div>
  </el-menu>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SubHeader',
  data() {
    return {
      activeIndex: '',
      categories: [],
    };
  },
  created() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      axios.get(`/api/web/categories`)
          .then(res => {
            this.categories = res.data.data;
          });
    },
    handleSelect(key, keyPath) {
      console.log('kay', key, 'keypath', keyPath);
    },
    clickCategory(slug) {
      this.$router.push(`/products?category=${slug}`);
    },
  }
};
</script>

<style>
.el-menu-demo {
  border-bottom: 0px;
}
</style>
