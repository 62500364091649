<template>
  <div>
    <div class="fixed-cart-icon text-center" v-if="drawer==false" @click="openDrawer">
      <el-icon class="icon">
        <ShoppingCartFull/>
      </el-icon>
      <p>{{ products.length }} ITEMS</p>
      <div class="total_amount">
        <p> ৳ {{ total }}</p>
      </div>
    </div>
    <el-drawer v-model="drawer" :direction="direction" class="custom-drawer">
      <template #header>
        <h4>CART</h4>
      </template>
      <template #default>
        <el-card v-if="products.length" v-for="(product,index) in products" :key="index" class="my-5 border-0"
                 shadow="hover">
          <CartProduct :product="product" :is_sidebar="true"/>
        </el-card>
        <el-empty v-else description="Empty"/>

      </template>
      <template #footer>
        <div v-if="products.length">
          <div class="d-flex justify-content-between">
            <div class="text-center">
              <p>TOTAL CART </p>
              <h3>{{ total }}</h3>
            </div>
            <el-button type="primary" @click="confirmClick">PROCEED</el-button>
          </div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import CartProduct from '@/components/product/CartProduct';

export default {
  name: 'SidebarShopingCart',
  components: {
    CartProduct,
  },
  data() {
    return {
      drawer: false,
      direction: 'rtl',
    };
  },
  computed: {
    products() {
      return this.$store.state.shopingCart.cart;
    },
    total() {
      return this.$store.getters['shopingCart/cartTotal'];
    }
  },
  methods: {
    cancelClick() {

    },
    confirmClick() {
      this.$router.push('/checkout');
    },
    openDrawer() {
      this.drawer = true;
    }
  }
};
</script>

<style scoped>
.fixed-cart-icon {
  cursor: pointer;
  position: fixed;
  right: 0;
  z-index: 99999;
  /*padding: 10px;*/
  top: 50%;
  background: #151414;
  color: #ffffff;
  border-radius: 10px;
  border: 2px solid #eb4960;
}

.total_amount {
  background: #eb4960;
}

.fixed-cart-icon .icon {
  font-size: 30px;
  padding: 5px;
}

.fixed-cart-icon p {
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
}
</style>

<style>
.custom-drawer {
  width: 50% !important;
}
@media only screen and (max-width: 600px) {
  .custom-drawer {
  width: 90% !important;
}
}
</style>