<template>
  <div class="body">
    <Header  />
<!--    <el-container>-->
      <router-view></router-view>
<!--    </el-container>-->
    <Footer/>
    
  </div>
</template>

<script>
import Header from './Header.vue';
import SubHeader from './SubHeader';
import Footer from './Footer.vue';
export default {
  name: 'Master',
  components: {
    Header,
    SubHeader,
    Footer,
  },
};
</script>

<style scoped>

</style>
